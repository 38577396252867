export const reduceItemCost = items => {
    const reducer = (acc, value) => {
      let num = value.cost ? value.cost : 0;
      return acc + num;
    };
    return items.reduce(reducer, 0);
  };

export const formatFee = (fee) => {
    return fee < 0
        ? `-$${(Math.abs(fee) / 100).toFixed(2)}`
        : `$${(fee / 100).toFixed(2)}`;
};