const partner = process.env.REACT_APP_PARTNER
const stage = process.env.REACT_APP_STAGE
const target_mode = process.env.REACT_APP_MODE

const mode = target_mode === "standard" ? "" : target_mode

export const handleResponse = (response, onError = false) => {
    if (response.status !== 200) {
        return response
            .json()
            .then(error => {
                return Promise.reject(onError ? onError : error)
            })
            .catch(err => {
                if (response.status === 401 || response.status === 403) {
                    //window.location = '/unauthorized'
                    return Promise.reject(
                        `unauthorized access attempt:${response.status}`
                    )
                }
                else {
                    return Promise.reject(onError ? onError : err)
                }
            })
    }
    else {
        try {
            return response.json()
        }
        catch (error) {
            return Promise.reject(error)
        }
    }
}

export const getBarcodeDetails = (barcode) => {
    return fetch(`https://${partner}${mode}.barcode.service.${stage}.com/${partner}/barcode?partition=${barcode}`, {
        mode: 'cors',
        method: 'GET'
    })
}

export const postPayment = (body) => {
    return fetch(`https://${partner}${mode}.cash.api.${stage}.com/${partner}/payment`, {
        mode: 'no-cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}
