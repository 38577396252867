import React from "react";
import {formatFee} from "../utility";

const TransactionResult = ({result}) => {
    
    return (
        <div className={`card-completion ${result ? '' : 'hidden'}`}>
            <h2>Success!</h2>
            <p>Your payment of {formatFee(result.amount)} is complete!</p>
            <style jsx="true" global="true">
            {`
                .card-completion {
                    margin: 16px;
                    overflow: hidden;
                    transition: all 0.2s ease;
                    width: 490px;
                }
                .card-completion.hidden {
                    margin: 0px;
                    width: 0px;
                    transition: all 0.2s ease;
                }
            `}</style>
        </div>
    )
}

export default TransactionResult