import React, { useState } from 'react'
import PropTypes from "prop-types";
import { formatFee, reduceItemCost } from '../utility'

const CartTable = ({items}) => {
    const [total] = useState(reduceItemCost(items));

    const itemRows = items.map((item, index) => {
        return <div className='item-row' key={`${item.name}-${index}`}>
            <h4>{item.item}</h4><h4>{formatFee(item.cost)}</h4>
        </div>
    })


    return (
        <div className="cart-table">
            <h2>Items</h2>
            {itemRows}
            <div className="total-row">
                <h3 className='strong'>Total: {formatFee(total)}</h3>
            </div>
            <style jsx="true" global="true">
        {`
            .cart-table {
                width: 400px;
                padding: 20px;
                margin: 0px 20px;
            }
            .item-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
            }
            .item-row:hover {
                border-bottom: 1px solid var(--grey-1);
            }
            .total-row {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        `}</style>
        </div>
    )
}

CartTable.propTypes = {
    items: PropTypes.array.isRequired
  };

export default CartTable