import React from "react";
import './App.css'
import { GlobalStyle, PortalHead, ModalSpinner } from "@paytheory/pay-theory-ui";
import CartTable from "./CartTable";
import CheckoutOptions from "./CheckoutOptions";
import { reduceItemCost } from "./utility";

const items = [
  {
    item: 'Hat',
  cost: 2500
  },
  {
    item: 'Shirt',
  cost: 3500
  },
  {
    item: 'Sweatshirt',
  cost: 4000
  }
]

export default function App() {

  return (
    <div className="App" id='container'>
        <GlobalStyle />
        <PortalHead />
        <div className="body">
          <CartTable items={items} />
          <CheckoutOptions amount={reduceItemCost(items)}/>
        </div>
        <ModalSpinner />
    </div>
  );
}
