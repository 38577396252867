import React, { useState, useEffect } from "react";
import { TabMenu, TabPage, Button, StockTags, openSpinner, closeSpinner , usePortalNotification} from "@paytheory/pay-theory-ui"

import BarcodeResult from "../BarcodeResult";
import TransactionResult from "../TransactionResut";

const MENU_ITEMS = {
    CASH: {
        menu: 'cash-menu',
        tab: 'cash-tab',
    },
    CARD: {
        menu: 'card-menu',
        tab: 'card-tab',
    },
    ACH: {
        menu: 'ach-menu',
        tab: 'ach-tab',
    }
}

const queryParams = new URLSearchParams(window.location.search)
const apiKey = queryParams.get('api_key') ? queryParams.get('api_key') : false

const CheckoutOptions = ({ amount }) => {
    const {
        ErrorMessage
      } = usePortalNotification();

    const [loaded, setLoaded] = useState(false);
    const [valid, isValid] = useState("");
    const [payTheory, setPayTheory] = useState(false);
    const [error, setError] = useState(false);
    const [result, setResult] = useState(false);
    const [barcode, setBarcode] = useState(false);
    const [statusMessage, setStatusMessage] = useState(<div />)
    const [API_KEY] = useState(apiKey);
    
    useEffect(() => {
        if (API_KEY === false) {
            ErrorMessage("API Key must be provided in URL as /?api_key=")
        }
    },[API_KEY])

    const BUYER_OPTIONS = {
        first_name: "Cash",
        last_name: "Test"
    };
    const FEE_MODE = window.paytheory.SURCHARGE;

    const selectTab = selected => {
        const menu = document.getElementById(selected.menu)
        const tab = document.getElementById(selected.tab)

        tab.classList.remove('gone')
        tab.classList.add('tab-visible')
        menu.classList.add('active-tab')
    }
    const clearUnselected = unselected => {
        unselected.forEach(item => {
            const menu = document.getElementById(item.menu)
            const tab = document.getElementById(item.tab)
            tab.classList.remove('tab-visible')
            tab.classList.add('gone')
            menu.classList.remove('active-tab')
        })
    }
    const changeTab = selected => {
        /* istanbul ignore next */
        switch (selected) {
        case MENU_ITEMS.CASH:
            clearUnselected([MENU_ITEMS.CARD, MENU_ITEMS.ACH])
            break
        case MENU_ITEMS.ACH:
            clearUnselected([MENU_ITEMS.CARD, MENU_ITEMS.CASH])
            break
        default:
            clearUnselected([MENU_ITEMS.ACH, MENU_ITEMS.CASH])
        }
        selectTab(selected)
    }

    const menuItems = [
        {
            id: MENU_ITEMS.CARD.menu,
            label: "CARD",
            active: "active-tab",
            icon: "credit-card",
            action: () => changeTab(MENU_ITEMS.CARD)
        },
        {
            id: MENU_ITEMS.ACH.menu,
            label: "ACH",
            active: "",
            icon: "money-check-alt",
            action: () => changeTab(MENU_ITEMS.ACH)
        },
        {
            id: MENU_ITEMS.CASH.menu,
            label: "CASH",
            active: "",
            icon: "money-bill-wave",
            action: () => changeTab(MENU_ITEMS.CASH)
        }
      ];

    const initTransaction = type => () => {
        payTheory.initTransaction(amount, BUYER_OPTIONS, false)
        openSpinner()
    }

    useEffect(() => {
        const STYLES = {
            default: {
                color: "black",
                fontSize: "14px",
                fontFamily: "halyard-text, inter, arial, sans-serif",
                background: 'transparent'
            },
            error: {
                color: "#d9534f",
                fontSize: "14px"
            }
        };

        const TAGS = {
            "pay-theory-account-code": "html-demo",
            "pay-theory-reference": "credit-card"
        };

        if (window.paytheory && !loaded) {
            setLoaded(true);

            window.paytheory
                .create(API_KEY, STYLES, TAGS, FEE_MODE)
                .then((myPayTheory) => {
                    myPayTheory.mount();

                    // handle callbacks
                    myPayTheory.readyObserver((ready) => {
                        setPayTheory(myPayTheory);
                    });

                    myPayTheory.validObserver((valid) => {
                        isValid(valid);
                    });

                    myPayTheory.errorObserver((error) => {
                        if (error) {
                            setError(error);
                        }
                        else {
                            setError(false);
                        }
                    });

                    const completionListener = (transactionResult) => {
                        setResult(transactionResult);
                        closeSpinner();
                    };
                    myPayTheory.transactedObserver(completionListener);

                    const cashListener = (transactionResult) => {
                        setBarcode(transactionResult);
                        closeSpinner();
                    };

                    myPayTheory.cashObserver(cashListener);
                });
        }
    }, [API_KEY, FEE_MODE, loaded]);

    return (
        <div className="checkout-options">
            <div className={`fields ${result || barcode ? 'hide' : ''}`}>
            <TabMenu items={menuItems}/>
            <TabPage id={MENU_ITEMS.CARD.tab}>
                <div id="pay-theory-credit-card" className='input-field'></div>
                <div id="pay-theory-credit-card-account-name" className='input-field'></div> 
                <div id="pay-theory-credit-card-address-1" className='input-field'></div>
                <div id="pay-theory-credit-card-address-2" className='input-field'></div>
                <div id="pay-theory-credit-card-city" className='input-field'></div>
                <div className="state-zip">
                    <div id="pay-theory-credit-card-state" className='input-field'></div>
                    <div id="pay-theory-credit-card-zip" className='input-field'></div>
                </div>
                <Button
                  label="Checkout"
                  name='card-button'
                  onClick={initTransaction('card')}
                  primary
                  disabled={!valid.includes('card')}
                />
            </TabPage>
            <TabPage id={MENU_ITEMS.ACH.tab} visibility='gone'>
                <div id="pay-theory-ach-account-name" className='input-field'></div>
                <div id="pay-theory-ach-account-number" className='input-field'></div>
                <div id="pay-theory-ach-routing-number" className='input-field'></div>
                <div id="pay-theory-ach-account-type" className='input-field'></div>
                <Button
                  label="Checkout"
                  name='ach-button'
                  onClick={initTransaction('ach')}
                  primary
                  disabled={!valid.includes('ach')}
                />
            </TabPage>
            <TabPage id={MENU_ITEMS.CASH.tab} visibility='gone'>
                <div id="pay-theory-cash-name" className='input-field'></div>
                <div id="pay-theory-cash-contact" className='input-field'></div>
                <Button
                  label="Generate Barcode"
                  name='cash-button'
                  onClick={initTransaction('cash')}
                  primary
                  disabled={!valid.includes('cash')}
                />
            </TabPage>
            </div>
            <TransactionResult result={result} />
            <BarcodeResult barcode={barcode} amount={amount}/>
            {statusMessage}
            <style jsx="true" global="true">
            {`
                .checkout-options {
                    display: flex;
                }
                .fields {
                    margin: 0px 16px;
                    width: 400px;
                    transition: all 0.5s;
                    overflow: hidden;
                    opacity: 1;
                }

                .fields.hide {
                    margin: 0px;
                    width: 0px;
                    opacity: 0;
                    transition: all 0.5s;
                }
                .input-field {
                    border: 1px solid var(--grey-1);
                    border-radius: 8px;
                    height: 56px;
                    padding: 0px 0px 0px 16px;
                    margin: 8px 0px;
                }
            `}</style>
        </div>
    )
}

export default CheckoutOptions;
